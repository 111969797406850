var ns;
ns.App.factory('LocationsService', function(HttpFactory, API_ENDPOINT, DsFactory, Utils){
  return {
    getLocations: function(sid, dsConf){
      var endpoint = API_ENDPOINT + "/locations?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getLocationsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/locations';
      var prefix = "?";
      if(sid != null && !angular.isUndefined(sid)){
        endpoint += "?screen_id=" + sid;
        prefix = "&";
      }
      if(!angular.isUndefined(params)){
        endpoint += prefix + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getLocation: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/locations/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    //FN_60030 ロケ基本情報設定登録
    //ロケ基本情報設定登録
    storeForLocation: function(params){
      var endpoint = API_ENDPOINT + '/locations';
      return HttpFactory.createPost(endpoint, params);
    },
    //ロケ基本情報設定更新
    updateForLocation: function(ids, params){
      var endpoint = API_ENDPOINT + '/locations/batch_update';
      return HttpFactory.createPut(endpoint, {"ids":ids, "location": params});
    },
    //ロケマスタ削除
    deleteForLocation: function(id){
      var endpoint = API_ENDPOINT + '/locations/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //複数削除
    multiDeleteForLocation: function (ids) {
      var endpoint = API_ENDPOINT + '/locations/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    },
    doSearchLocations: function(sid, dsConf, query){
      var endpoint = API_ENDPOINT + '/locations/multi_search?screen_id=' + sid;
      endpoint += query;
      return DsFactory.create(dsConf, endpoint);
    },
    doSearch: function(sid, dsConf, query){
      var endpoint = API_ENDPOINT + '/locations/search?screen_id=' + sid;
      endpoint += "&" + Utils.getQueryStr({q: query});
      return DsFactory.create(dsConf, endpoint);
    }
  };
});

