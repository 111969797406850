var ns;
ns.App.factory('UnitComponentsService', function(HttpFactory, API_ENDPOINT, DsFactory, Utils){
  return {
    getUnitComponents: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/unit_components?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitComponentsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_components?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    //FN70030 ロール追加・編集登録
    updateForUnitComponent: function(params){
      var endpoint = API_ENDPOINT + '/unit_components/batch_update';
      return HttpFactory.createPost(endpoint, params);
    }
  };
});

