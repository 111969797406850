var ns;
ns.App.factory('ClientsService', function(API_ENDPOINT, DsFactory, HttpFactory, Utils){
  return {
    getClients: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + "/clients?screen_id=" + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getClient: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/clients/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getClientData: function(sid, id){
      var endpoint = API_ENDPOINT + '/clients/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getClientsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/clients?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    //FN_50050 依頼主追加・編集
    //依頼主登録
    storeForClient: function(params){
      var endpoint = API_ENDPOINT + '/clients';
      return HttpFactory.createPost(endpoint, params);
    },
    //依頼主更新
    updateForClient: function(params, id){
      var endpoint = API_ENDPOINT + '/clients/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //依頼主削除
    deleteForClient: function(id){
      var endpoint = API_ENDPOINT + '/clients/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //依頼主複数削除
    multiDeleteForClient: function(ids){
      var endpoint = API_ENDPOINT + '/clients/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    }
  };
});

