var ns;
ns.App.factory('StocksService', function($http, API_ENDPOINT, HttpFactory, DsFactory, Utils){
  return {
    getStocksByMaterialId: function(sid, dsConf, materialId){
      var endpoint = API_ENDPOINT + '/stocks?screen_id=' + sid + "&material_ids[]=" + materialId;
      return DsFactory.create(dsConf, endpoint);
    },
    getStocksDataByMaterialId: function(sid, materialId){
      var endpoint = API_ENDPOINT + '/stocks?screen_id=' + sid + "&material_ids[]=" + materialId;
      return HttpFactory.createGet(endpoint);
    },
    getStocksDataByMaterialId: function(sid, materialId){
      var endpoint = API_ENDPOINT + '/stocks?screen_id=' + sid + "&material_ids[]=" + materialId;
      return HttpFactory.createGet(endpoint);
    },
    getStocks: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/stocks?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getStocksData: function(sid, params){
      var endpoint = API_ENDPOINT + '/stocks?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getStock: function(sid, dsConf, id, params, byHttp){
      var endpoint = API_ENDPOINT + '/stocks/' + id + "?screen_id=" + sid;
      if(angular.isUndefined(byHttp)){
        byHttp = false;
      }
      if(byHttp){
        return HttpFactory.createGet(endpoint);
      }else{
        return DsFactory.create(dsConf, endpoint);
      }
    },
    getStockData: function(sid, id){
      var endpoint = API_ENDPOINT + '/stocks/' + id + '?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },
    storeStocks: function(params){
      var endpoint = API_ENDPOINT + "/stocks/batch_store";
      return HttpFactory.createPost(endpoint, {data: params});
    },
    updateStockQuantities: function(params){
      var endpoint = API_ENDPOINT + "/stocks/batch_update_quantities";
      return HttpFactory.createPut(endpoint, {data: params});
    },
    updateStockInfos: function(params){
      var endpoint = API_ENDPOINT + "/stocks/batch_update_infos";
      return HttpFactory.createPut(endpoint, {data: params});
    },
    mergeStocks: function(parentId, childIds) {
      var endpoint = API_ENDPOINT + '/stocks/' + parentId + '/batch_merge_stocks';
      var params = {}
      if(!angular.isUndefined(childIds)){
        params["ids"] = childIds;
      }
      return HttpFactory.createPost(endpoint, params);
    },
    doSearchStocks: function(sid, dsConf, query){
      var endpoint = API_ENDPOINT + '/stocks/search?screen_id=' + sid;
      endpoint += query;
      return DsFactory.create(dsConf, endpoint);
    },
    getProgressOverviewData: function(){
      var endpoint = API_ENDPOINT + '/st_progress/overview';
      return HttpFactory.createGet(endpoint);
    },
    // trigger
    triggerTransport: function(sid, id){
      var endpoint = API_ENDPOINT + '/stocks/trigger_transport';
      return HttpFactory.createPost(endpoint, {'screen_id': sid, 'ids': id});
    }
  };
});

