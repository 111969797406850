var ns;
ns.App.factory('MaterialsService', function(
  $http, $location, API_ENDPOINT, DsFactory, Utils, HttpFactory
  ){
  return {
    getMaterials: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/materials?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialCountsData: function(){
      var endpoint = API_ENDPOINT + '/materials/counts';
      return HttpFactory.createGet(endpoint);
    },
    getMaterialPackageCountsData: function(){
      var endpoint = API_ENDPOINT + '/material_packages/counts';
      return HttpFactory.createGet(endpoint);
    },
    getMaterialsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/materials?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getMaterial: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/materials/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialData: function(sid, id){
      var endpoint = API_ENDPOINT + '/materials/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getMaterialPackagesByMaterialId: function(sid, dsConf, params, id){
      var endpoint = API_ENDPOINT + '/materials/' + id + "/material_packages?screen_id=" + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialPackagesByMaterialIdData: function(sid, id){
      var endpoint = API_ENDPOINT + '/materials/' + id + "/material_packages";
      if(sid != null && !angular.isUndefined(sid)){
        endpoint += "?screen_id=" + sid;
      }
      return HttpFactory.createGet(endpoint);
    },
    getMaterialPackage: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/material_packages/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialPackageData: function(sid, id){
      var endpoint = API_ENDPOINT + '/material_packages/' + id;
      if(sid != null && !angular.isUndefined(sid)){
        endpoint += "?screen_id=" + sid;
      }
      return HttpFactory.createGet(endpoint);
    },
    getMaterialPackages: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/material_packages?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialPackagesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/material_packages?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getMaterialPackageMaterialSet: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/material_packages/' + id + '/material_sets?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialSets: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/material_sets/set_material_packages?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialSetsData: function(sid, material_package_id, params){
      var endpoint = API_ENDPOINT + '/material_packages/' + material_package_id + '/material_sets';
      var prefix = "?";
      if(!angular.isUndefined(sid)){
        endpoint += prefix + "screen_id=" + sid;
        prefix = "&";
      }
      if(!angular.isUndefined(params)){
        endpoint += prefix + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getMaterialSetIndex: function(sid, params){
      var endpoint = API_ENDPOINT + '/material_sets?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getMaterialSetData: function(sid, id){
      var endpoint = API_ENDPOINT + '/material_sets/' + id + '?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },
    getMaterialPackageStocks: function(sid, dsConf, material_package_id){
      var endpoint = API_ENDPOINT + '/material_packages/' + material_package_id + "/stocks?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getMaterialPackageStocksData: function(sid, material_package_id, only_fixed){
      var endpoint = API_ENDPOINT + '/material_packages/' + material_package_id + "/stocks?screen_id=" + sid + "&only_fixed=" + only_fixed;
      return HttpFactory.createGet(endpoint);
    },
    getNovelties: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/material_packages/novelties?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getNoveltiesData: function(sid){
      var endpoint = API_ENDPOINT + '/material_packages/novelties?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },
    doSearch: function(sid, dsConf, query){
      var endpoint = API_ENDPOINT + '/materials/search?screen_id=' + sid;
      endpoint += "&" + Utils.getQueryStr({q: query});
      return DsFactory.create(dsConf, endpoint);
    },
    doSearchMaterials: function(sid, dsConf, query){
      var endpoint = API_ENDPOINT + '/materials/multi_search?screen_id=' + sid;
      endpoint += query;
      return DsFactory.create(dsConf, endpoint);
    },
    doSearchMultiMaterialPackages: function(sid, dsConf, query){
      var endpoint = API_ENDPOINT + '/material_packages/multi_search?screen_id=' + sid;
      endpoint += query;
      return DsFactory.create(dsConf, endpoint);
    },
    doSearchMaterialPackages: function(sid, dsConf, query, kind){
      var endpoint = API_ENDPOINT + '/material_sets/set_material_packages/search?screen_id=' + sid;
      endpoint += "&" + Utils.getQueryStr({q: query}) + "&" + Utils.getQueryStr({kind: kind});
      return DsFactory.create(dsConf, endpoint);
    },
    //FN_50100 商品登録
    storeForMaterial: function(material, material_extension){
      var endpoint = API_ENDPOINT + '/materials';
      if(material_extension != null && !angular.isUndefined(material_extension) && Object.keys(material_extension).length > 0){
        return HttpFactory.createPost(endpoint, {"material":material, "material_extension": material_extension});
      } else {
        return HttpFactory.createPost(endpoint, {"material":material});
      }
    },
    //FN_50100 商品更新
    updateForMaterial: function(id, material, material_extension){
      var endpoint = API_ENDPOINT + '/materials/' + id;
      return HttpFactory.createPut(endpoint, {"material":material, "material_extension": material_extension});
    },
    //FN_50100 商品削除
    deleteForMaterial: function(id){
      var endpoint = API_ENDPOINT + '/materials/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //FN_50100 荷姿登録
    registForMaterialPackage: function(params){
      var endpoint = API_ENDPOINT + '/material_packages';
      return HttpFactory.createPost(endpoint, params);
    },
    registForMaterialPackageLocation: function(id, params){
      var endpoint = API_ENDPOINT + '/material_packages/' + id + '/store_material_package_location';
      return HttpFactory.createPost(endpoint, params);
    },
    //FN_50100 荷姿更新
    updateForMaterialPackage: function(id, params){
      var endpoint = API_ENDPOINT + '/material_packages/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    updateForMaterialPackageLocation: function(id, params){
      var endpoint = API_ENDPOINT + '/material_packages/' + id + '/update_material_package_location';
      return HttpFactory.createPut(endpoint, params);
    },
    //FN_50100 荷姿削除
    deleteForMaterialPackage: function(id){
      var endpoint = API_ENDPOINT + '/material_packages/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //FN_50120 セット品ルール登録
    registForMaterialSet: function(params){
      var endpoint = API_ENDPOINT + '/material_sets/batch_store_or_update';
      return HttpFactory.createPost(endpoint, {"data":params});
    },
    //FN_50170 商品TC一括設定
    updateForMaterialIsTc: function(ids, material){
      var endpoint = API_ENDPOINT + '/materials/multi_update';
      return HttpFactory.createPut(endpoint, {"ids":ids, "material": material});
    },
    //商品複数削除
    multiDeleteForMaterial: function(ids){
      var endpoint = API_ENDPOINT + '/materials/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    },
    //商品荷姿複数削除
    multiDeleteForMaterialPackage: function(ids){
      var endpoint = API_ENDPOINT + '/material_packages/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    },
    //商品セット複数削除
    multiDeleteForMaterialSet: function(ids){
      var endpoint = API_ENDPOINT + '/material_sets/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    }
  };
});

