var ns;
ns.App.factory('ElementCodesService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils, $http
  ){
  return {
    getOwnerElementCodes: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/owner_codes/search?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    //getOwnerElementCodes: function(sid, dsConf){
    //  var endpoint = API_ENDPOINT + '/owner_codes/search?screen_id=' + sid;
    //  return DsFactory.create(dsConf, endpoint);
    //},
    getOwnerElementCodesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/owner_codes/search?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    //FN_50020 オーナーステータス設定登録
    //登録
    storeForOwnerElementCode: function(params){
      var endpoint = API_ENDPOINT + '/owner_codes';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForOwnerElementCode: function(params, id){
      var endpoint = API_ENDPOINT + '/owner_codes/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //削除
    deleteForOwnerElementCode: function(id){
      var endpoint = API_ENDPOINT + '/owner_codes/' + id;
      return $http.delete(endpoint);
    },
  };
});

