var ns;
ns.App.factory('ScreensService', function($http, API_ENDPOINT){
  return {
    getConfig: function(screen_id){ 
      return $http.get('/screens/' + screen_id + "/config");
      //return $http.get('/screens/' + screen_id + "/config", {cache: true});
    },
    getConfigTreeList: function(screen_id){
      return $http.get('/screens/' + screen_id + "/treelist");
      //return $http.get('/screens/' + screen_id + "/config", {cache: true});
    }
  };
});

