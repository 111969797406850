var ns;
ns.App.factory('ModalFactory', function($window, $document, $q, MES_MST){
  return {
    //通知モーダルを表示
    createIModal: function (code) {
      var deferred = $q.defer();
      setTimeout(function(){
        deferred.notify({status: "OK"});
        var modalobj = document.getElementById('dialog-modal-info');
        var modalelm = angular.element(modalobj);
        var kendoWindow = modalelm.kendoWindow({
          width: "500px",
          modal: true
        });
        kendoWindow
        .find("#dialog-modal-title-area")[0].textContent =  MES_MST[code].message;

        kendoWindow
        .find(".ok-button")
          .click(function() {
            kendoWindow.data("kendoWindow").close();
            deferred.resolve({status: "OK"});
          })
          .end();
        modalelm.data("kendoWindow").center().open();
      }, 1);
      return deferred.promise;
    },
    //通知モーダルを表示(文字列指定)
    createIModalByStr: function (str) {
      var deferred = $q.defer();
      setTimeout(function(){
        deferred.notify({status: "OK"});
        var modalobj = document.getElementById('dialog-modal-info');
        var modalelm = angular.element(modalobj);
        var kendoWindow = modalelm.kendoWindow({
          width: "500px",
          modal: true
        });
        kendoWindow
        .find("#dialog-modal-title-area")[0].textContent =  str;
        kendoWindow
        .find(".ok-button")
          .click(function() {
            kendoWindow.data("kendoWindow").close();
            deferred.resolve({status: "OK"});
          })
          .end();
        modalelm.data("kendoWindow").center().open();
      }, 1);
      return deferred.promise;
    },
    //警告モーダルを表示
    createWModalStr: function (str) {
      var deferred = $q.defer();
      setTimeout(function(){
        var modalobj = document.getElementById('dialog-modal-alert');
        var modalelm = angular.element(modalobj);
        var result = false;
        var kendoWindow = modalelm.kendoWindow({
          width: "500px",
          modal: true,
        });
        kendoWindow
        .find("#dialog-modal-title-area")[0].textContent =  str;
        kendoWindow
        .find(".ok-button")
          .click(function() {
            result = true;
            kendoWindow.data("kendoWindow").close();
            deferred.resolve({status: "OK"});
          })
          .end();
        kendoWindow
        .find(".cancel-button")
          .click(function() {
            kendoWindow.data("kendoWindow").close();
          })
          .end();
        modalelm.data("kendoWindow").center().open();
      }, 1);
      return deferred.promise;
    },
    //確認モーダルを表示
    createCModalStr: function (str) {
      var deferred = $q.defer();
      setTimeout(function(){
        var modalobj = document.getElementById('dialog-modal-confirm');
        var modalelm = angular.element(modalobj);
        var result = false;
        var kendoWindow = modalelm.kendoWindow({
          width: "500px",
          modal: true,
        });
        kendoWindow
          .find("#dialog-modal-title-area")[0].textContent =  str;
        kendoWindow
          .find(".ok-button")
          .click(function() {
            result = true;
            kendoWindow.data("kendoWindow").close();
            deferred.resolve({status: "OK"});
          })
          .end();
        kendoWindow
          .find(".cancel-button")
          .click(function() {
            deferred.reject({status: "NG"});
            kendoWindow.data("kendoWindow").close();
          })
          .end();
        modalelm.data("kendoWindow").center().open();
      }, 1);
      return deferred.promise;
    },
    //警告モーダルを表示
    createWModal: function (code) {
     return this.createWModalStr(MES_MST[code].message);
    },
    //確認モーダルを表示
    createCModal: function (code) {
      return this.createCModalStr(MES_MST[code].message);
    },
    //エラーモーダルを表示
    createEModal: function (code) {
    },
    //エラーモーダルを表示
    createEModalByServerData: function (data) {
      var deferred = $q.defer();
      var modalobj = document.getElementById('dialog-modal-error');
      var modalelm = angular.element(modalobj);
      var kendoWindow = modalelm.kendoWindow({
        width: "500px",
        modal: true
      });
      kendoWindow
      .find(".ok-button")
        .click(function() {
          kendoWindow.data("kendoWindow").close();
          deferred.resolve({status: "OK"});
        })
        .end();
      kendoWindow
      .find("#dialog-modal-title-area")[0].textContent = data["messages"];
      modalelm.data("kendoWindow").center().open();
      return deferred.promise;
    },
    //入力モーダルを表示
    createInputModal: function (title, content)
    {
      var modalobj = document.querySelectorAll('[id="dialog-modal-input"]');
      var modalelm = angular.element(modalobj[modalobj.length - 1]);
      var kendoWindow = modalelm.kendoWindow({
        width: "500px",
        modal: false
      });
      if(modalobj.length > 1){
        kendoWindow.data("kendoWindow").destroy();
      }

      var deferred = $q.defer();
      setTimeout(function ()
      {
        var modalobj = document.getElementById('dialog-modal-input');
        var modalelm = angular.element(modalobj);
        var result = false;
        var kendoWindow = modalelm.kendoWindow({
          width: "500px",
          modal: true
        });

        kendoWindow
          .find("#dialog-modal-title-area")[0].textContent = title;
        kendoWindow
          .find(".ok-button")
          .click(function ()
          {
            result = true;
            var val = document.getElementById('dialog-modal-input-inputarea').value;
            kendoWindow.data("kendoWindow").close();
            deferred.resolve(val);
          })
          .end();
        kendoWindow
          .find(".cancel-button")
          .click(function ()
          {
            if(modalobj.length > 1){
              kendoWindow.data("kendoWindow").destroy();
            }
            else {
              kendoWindow.data("kendoWindow").close();
            }
          })
          .end();
        document.getElementById('dialog-modal-input-inputarea').value = content;
        modalelm.data("kendoWindow").center().open();
      }, 1);
      return deferred.promise;
    },
  };
});

