var ns;
ns.App.directive('locationsSelect', function(LocationsService, API_ENDPOINT ){
  return {
    // 要素名を対象としたディレクティブであるという指定 
    restrict: 'E',
    // ディレクティブの内容となるテンプレート 
    template:
  '<span><select ng-model="location" ng-change="ngModel = location.id" k-options="selectoption"  kendo-drop-down-list="kendolist" k-rebind="locations"  ' +
      'ng-options="l.lccd_nm for l in locations track by l.id">' +
      '</select></span>',
    replace: true,
    scope: {
      ngModel: "=",
      callback: "&"
    },
    link: function(scope, element, attrs) {
      scope.selectoption = {
        filter: "contains"
      };

      LocationsService.getLocationsData(null, null)
      .success(function(data){
        var i = 0;
        var length = data.length;
        var matchedObj = data[0];
        var selectedIndex = 0;
        for(;i < length; i++){
          var location = data[i];
          if(scope.ngModel == location.id){
            matchedObj = location;
            selectedIndex = i;
            break;
          }
        }
        scope.location = matchedObj;
        scope.locations = [""];
        scope.locations = scope.locations.concat(data);
        scope.ngModel = matchedObj.id;
        setTimeout(function(){
          scope.kendolist.select(selectedIndex+1);
          scope.location = matchedObj;
          scope.ngModel = matchedObj.id;
        }, 100);
        scope.callback();
      });
    }
  };
});

