var ns;
ns.App.factory('UsersService', function(HttpFactory, API_ENDPOINT, DsFactory, Utils){
  return {
    getUsers: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/users/available?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getUsersData: function(){
      var endpoint = API_ENDPOINT + '/users/available';
      return HttpFactory.createGet(endpoint);
    },
    getCurrentUserData: function(){
      var endpoint = API_ENDPOINT + '/users/current';
      return HttpFactory.createGet(endpoint);
    },
    getUserData: function(id){
      var endpoint = API_ENDPOINT + '/users/available/' + id;
      return HttpFactory.createGet(endpoint);
    },
    getUser: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/locations/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    //FN_70013 ユーザー追加登録
    //登録
    storeForUser: function(params){
      var endpoint = API_ENDPOINT + '/users/available';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForUser: function(id, params){
      var endpoint = API_ENDPOINT + '/users/available/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //削除
    deleteForUser: function(params){
      var endpoint = API_ENDPOINT + '/users/available';
      if(!angular.isUndefined(params)){
        endpoint += "?" + Utils.getQueryStr(params);
      }
      return HttpFactory.createDelete(endpoint);
    }
  };
});

