var ns;
ns.App.factory('UnitCodesService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils, $http
  ){
  return {
    getUnitCodes: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_codes/group_codes?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    getUnitCodesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_codes/search?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    //FN_70015 ユニット別ステータス別設定登録
    //登録
    storeForUnitCode: function(params){
      var endpoint = API_ENDPOINT + '/unit_codes';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForUnitCode: function(params, id){
      var endpoint = API_ENDPOINT + '/unit_codes/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //削除
    deleteForUnitCode: function(id){
      var endpoint = API_ENDPOINT + '/unit_codes/' + id;
      return HttpFactory.createDelete(endpoint);
    }
  };
});

