var ns;
ns.App.factory('CarriersService', function($http, API_ENDPOINT, DsFactory, HttpFactory, Utils){
  return {
    getCarriers: function(sid, dsConf){
      var endpoint = API_ENDPOINT + "/carriers?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getCarrier: function(sid, dsConf, id){
      //var endpoint = API_ENDPOINT + '/carriers/' + id + "?screen_id=" + sid;
      var endpoint = API_ENDPOINT + '/carriers?ids[]=' + id + "&screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getCarriersData: function(sid, params){
      var endpoint = API_ENDPOINT + '/carriers?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getCarrierData: function(sid, id){
      var endpoint = API_ENDPOINT + '/carriers/' + id + "?screen_id=" + sid;
      //var endpoint = API_ENDPOINT + '/carriers?ids[]=' + id + "&screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getAvailableCarriers: function(sid, dsConf){
      var endpoint = API_ENDPOINT + "/carriers/available?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getAvailableCarriersData: function(sid){
      var endpoint = API_ENDPOINT + "/carriers/available?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getCarrierTimeZonesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/carrier_timezones?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getCarrierTimeZoneData: function(sid, id){
      var endpoint = API_ENDPOINT + '/carrier_timezones/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getElementCarrierTypesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/element_carrier_types';
      var prefix = "?";
      if(sid != null && !angular.isUndefined(sid)){
        endpoint += "?screen_id=" + sid;
        prefix = "&";
      }
      if(!angular.isUndefined(params)){
        endpoint += prefix + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitCarrierTypes: function (sid, dsConf, params) {
      var endpoint = API_ENDPOINT + '/unit_carrier_types?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitCarrierTypesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_carrier_types';
      var prefix = "?";
      if(sid != null && !angular.isUndefined(sid)){
        endpoint += "?screen_id=" + sid;
        prefix = "&";
      }
      if(!angular.isUndefined(params)){
        endpoint += prefix + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitCarrierTypeData: function(sid, id){
      var endpoint = API_ENDPOINT + '/unit_carrier_types/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    //登録
    storeForUnitCarrierType: function (params) {
      var endpoint = API_ENDPOINT + '/unit_carrier_types';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForUnitCarrierType: function (params, id) {
      var endpoint = API_ENDPOINT + '/unit_carrier_types/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //削除
    deleteForUnitCarrierType: function (id) {
      var endpoint = API_ENDPOINT + '/unit_carrier_types/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //複数削除
    multiDeleteForUnitCarrierType: function (ids) {
      var endpoint = API_ENDPOINT + '/unit_carrier_types/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    }
  };
});

