var ns;
ns.App.directive("selectInit", function () {
  return {
    scope: {
      ngModel: "=",
    },
    restrict: "A",
    link: function (scope, element, attrs) {
      scope.ngModel = element[0].options[1].value;
    }
  };
});
