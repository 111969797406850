var ns;
ns.App.factory('UnitScreensService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getUnitScreens: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/unit_screens?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitScreensData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_screens?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitScreensCanCsv: function(params){
      var endpoint = API_ENDPOINT + '/unit_screens/can_csv?';
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitScreensCanDisplaySetting: function(params){
      var endpoint = API_ENDPOINT + '/unit_screens/can_display_setting?';
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    //FN70030 ロール追加・編集登録
    updateForUnitScreen: function(params){
      var endpoint = API_ENDPOINT + '/unit_screens/batch_update';
      return HttpFactory.createPost(endpoint, params);
    }
  };
});

