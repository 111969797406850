var ns;
(function () {
  var UnitTopController = function (
    $scope, $location, $http, $window, $routeParams,
    $controller, SessionsService, ModalFactory, UnitsService,
    ArrivesService, ShipmentsService, TodosService, IfApisService, StocksService
  ) {
    $controller('BaseController', {$scope: $scope});
    ArrivesService.getProgressOverviewData()
    .success(function(data){
      $scope.ar_overview = data[0].overview;
    });
    ShipmentsService.getProgressOverviewData()
    .success(function(data){
      $scope.sh_overview = data[0].overview;
    });
    StocksService.getProgressOverviewData()
      .success(function(data){
        $scope.st_overview = data[0].overview;
      });
    TodosService.getTodosData()
    .success(function(data){
      $scope.todos = data;
    });
    $scope.createTodo = function(){
      var content = $scope.todo_content;
      if(content === ""){
        return;
      }
      $scope.startLoading();
      TodosService.postTodo({cnt: content}).success(function(data){
        var id = data[0];
        $scope.todos.unshift({id: id, cnt: $scope.todo_content});
        $scope.todo_content = "";
        $scope.stopLoading();
      })
      .error(function (data) {
        $scope.stopLoading();
      });
    };
    $scope.deleteTodo = function(index){
      var todo = $scope.todos[index];
      $scope.startLoading();
      TodosService.deleteTodo(todo.id).success(function(data){
        $scope.todos.splice(index, 1);
        $scope.stopLoading();
      })
      .error(function (data) {
        $scope.stopLoading();
      });

    };
    $scope.getHttpIfApi = function(){
      $scope.startLoading();
      IfApisService.getHttpIfApi().success(function(data){
        res = data.data[0];
        if(res.status_cd == "00") {
          $scope.stopLoading();
          ModalFactory.createIModalByStr("残件数を取得しました。"
          + "\r\n商品マスタ：" + res.record[0]
          + "\r\n出荷指示：" + res.record[3]
          + "\r\n入荷予定：" + res.record[7])
          .then(function(){
          });

        }
        else{
          $scope.stopLoading();
          ModalFactory.createIModalByStr("残件数の取得に失敗しました。"
          + "\r\nステータスコード：" + res.status_cd)
          .then(function(){
          });

        }
        $scope.stopLoading();
      }).error(function (data) {
          $scope.stopLoading();
        });
    };

  };
  //add dependency
  UnitTopController.$inject = [
    '$scope', '$location', '$http', '$window', '$routeParams',
    '$controller', 'SessionsService', 'ModalFactory', 'UnitsService',
    'ArrivesService', 'ShipmentsService', 'TodosService', 'IfApisService', 'StocksService'
  ];
  ns.App.controller('UnitTopController', UnitTopController);


}());
