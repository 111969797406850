var ns;
(function () {
  var AllUnitsShipmentStatusController = function ($scope, $location, $http, $window, $routeParams, $controller, SessionsService, ModalFactory,
                                        UnitsService, Utils, HISTORY_SEARCH_RANGE) {
    $controller('BaseController', {$scope: $scope});

    //ユニットトップへのリンクを非表示
    document.getElementById('unittop').style.display="none";
    //ボタン2度押し防止
    $scope.registClicked = false;

    if(!$location.search()["sch_shp_dt"]) {
      $scope.sch_shp_dt = Utils.getPastDateObj(0);
    }
    else{
      $scope.sch_shp_dt = new Date($location.search()["sch_shp_dt"]);
    }

    var params = {
      sch_shp_dt: Utils.getDateStr($scope.sch_shp_dt)
    };
    UnitsService.getAllUnitsShipmentStatus(params)
      .success(function(data){
        $scope.allUnitsStatus = data["all_units_status"];
        $scope.eachUnitsStatus = data["each_units_status"];
        $scope.switchOpenStatus(true);
      });

    $scope.reload = function() {
      var params = {
        sch_shp_dt: Utils.getDateStr($scope.sch_shp_dt)
      };
      UnitsService.getAllUnitsShipmentStatus(params)
        .success(function(data){
          $scope.allUnitsStatus = data["all_units_status"];
          $scope.eachUnitsStatus = data["each_units_status"];
          $scope.switchOpenStatus(true);
        });
    };
    $scope.switchOpenStatus = function(flag){
      angular.forEach($scope.eachUnitsStatus, function(eachUnitStatus) {
        eachUnitStatus.is_display = flag;
      });
    };
  };
  //add dependency
  AllUnitsShipmentStatusController.$inject = [
    '$scope', '$location', '$http', '$window', '$routeParams', '$controller', 'SessionsService', 'ModalFactory', 'UnitsService',
    'Utils', 'HISTORY_SEARCH_RANGE'
  ];
  ns.App.controller('AllUnitsShipmentStatusController', AllUnitsShipmentStatusController);

}());
