ns.App.config(
  function($routeProvider, $locationProvider, $httpProvider, CSRF_TOKEN, SYSTEM_NAME) {
    kendo.culture("ja");
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: true
    });
    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
    $httpProvider.defaults.headers.common['csrf-token'] = CSRF_TOKEN;
    // 401エラーの場合にはログインページにリダイレクトするinterceptorを定義
    $httpProvider.interceptors.push(function ($q, $rootScope, $location) {
      return {
        'responseError': function(rejection) {
          var status = rejection.status;
          if (status === 401) {
            $location.path('/login');
          }else if (status === 403) {
            $location.path('/');
          } else {
            var config = rejection.config;
            if (config) {
              var method = config.method;
                var url = config.url;
              $rootScope.error = method + ' on ' + url + ' failed with status ' + status;
            }
          }
          return $q.reject(rejection);
        }
      };
    });
  }
);
