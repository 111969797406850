var ns;
ns.App.factory('TodosService', function(HttpFactory, API_ENDPOINT){
  return {
    getTodosData: function(){
      var endpoint = API_ENDPOINT + '/todos';
      return HttpFactory.createGet(endpoint);
    },
    postTodo: function(params){
      var endpoint = API_ENDPOINT + "/todos/batch_store";
      return HttpFactory.createPost(endpoint, params);
    },
    deleteTodo: function(id){
      var endpoint = API_ENDPOINT + "/todos/" + id;
      return HttpFactory.createDelete(endpoint);
    }
  };
});

