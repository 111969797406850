var ns;
ns.App.factory('ElementsService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils, $http, Upload
  ){
  return {
    getOwnerElements: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/owners/current?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getOwnerElementsData: function(sid){
      var endpoint = API_ENDPOINT + '/owners/current?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },
    getWarehouseElements: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/warehouses/current?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getWarehouseElementsData: function(sid){
      var endpoint = API_ENDPOINT + '/warehouses/current?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },
    //FN_50010 基本設定登録
    //登録
    storeForOwnerElement: function(params){
      var endpoint = API_ENDPOINT + '/owners/available';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForOwnerElement: function(id, params){
      var endpoint = API_ENDPOINT + '/owners/available/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //FN_60010 基本設定登録
    //登録
    storeForWarehouseElement: function(params){
      var endpoint = API_ENDPOINT + '/warehouses/available';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForWarehouseElement: function(params, id){
      var endpoint = API_ENDPOINT + '/warehouses/available/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
  };
});

