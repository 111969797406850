var ns;
ns.App.factory('AuthService', function($http, API_ENDPOINT){
  return {
    getUser: function(){ 
      return $http.get(API_ENDPOINT + '/sessions');
    },
    login: function(email, password, remember){
      return $http.post(API_ENDPOINT + '/sessions', {email: email, password:password, remember: remember});
    },
    logout: function(){
      return $http.delete(API_ENDPOINT + '/sessions');
    }
  };
});

