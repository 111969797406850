var ns;
ns.App.factory('SuppliersService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getSuppliers: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/suppliers?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getSuppliersData: function(sid, params){
      var endpoint = API_ENDPOINT + '/suppliers?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getSupplier: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/suppliers/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getSupplierData: function(sid, id){
      var endpoint = API_ENDPOINT + '/suppliers/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    //FN_50080 仕入先追加・編集
    //仕入先登録
    storeForSupplier: function(params){
      var endpoint = API_ENDPOINT + '/suppliers';
      return HttpFactory.createPost(endpoint, params);
    },
    //仕入先更新
    updateForSupplier: function(params, id){
      var endpoint = API_ENDPOINT + '/suppliers/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //仕入先削除
    deleteForSupplier: function(id){
      var endpoint = API_ENDPOINT + '/suppliers/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //仕入先削除
    multiDeleteForSupplier: function(ids){
      var endpoint = API_ENDPOINT + '/suppliers/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    },
    /**
     * 届け先コピー
     */
    copySupplier: function(type, id){
      var endpoint = API_ENDPOINT + '/suppliers/copy_create';
      return HttpFactory.createPost(endpoint, {type:type, sh_header_id:id});
    }
  };
});

