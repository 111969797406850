var ns, kendo;
ns.App.factory('DsFactory', function(BRIEF_PARAM){
  return {
    create: function(dsConf, endpoint, isBrief){
      //デフォルト引数
      if (typeof isBrief === 'undefined') {
        isBrief = true;
      }
      if(isBrief){
        if (endpoint.indexOf('?') === -1) {
          endpoint += "?" + BRIEF_PARAM;
        }else{
          endpoint += "&" + BRIEF_PARAM;
        }
      }
      dsConf.transport.read.url = endpoint;
      return new kendo.data.DataSource(dsConf);
    }
  };
});

