var ns;
ns.App.factory('UnitSettingsService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getUnitSettings: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/unit_settings?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitSettingsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_settings?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    //FN_70010 基本設定登録
    updateForUnitSetting: function(params){
      var endpoint = API_ENDPOINT + '/unit_settings/batch_update';
      return HttpFactory.createPost(endpoint, {"unit_settings": params});
    },
  };
});

