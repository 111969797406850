var ns;
ns.App.factory('UnitUserPermissionsService', function(HttpFactory, API_ENDPOINT, DsFactory, Utils){
  return {
    getUnitUserPermissionsData: function(){
      var endpoint = API_ENDPOINT + '/unit_user_permissions';
      return HttpFactory.createGet(endpoint);
    },
    //FN_70013 ユーザー追加登録
    //登録
    storeForUnitUserPermission: function(params){
      var endpoint = API_ENDPOINT + '/unit_user_permissions';
      return HttpFactory.createPost(endpoint, params);
    },
    //アサイン解除
    deleteForUnitUserPermission: function(id){
      var endpoint = API_ENDPOINT + '/unit_user_permissions/' + id;
      return HttpFactory.createDelete(endpoint);
    }
  };
});

