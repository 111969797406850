(function() {
  var SigninController= function($scope, $location, $window, $routeParams, $controller, AuthService) {
    $controller('BaseController', {$scope: $scope});
    $scope.login = function(){
      $scope.disabled = true;
      AuthService.login($scope.username, $scope.password)
        .success(function(data,status,headers,config){
          $window.location.reload();
          $location.path('/');
        })
        .error(function(data,status,headers,config){
          $scope.alart = data.message;
        });
    };
  };
  //add dependency
  SigninController.$inject = [
    '$scope', '$location', '$window', '$routeParams', '$controller', 
    'AuthService'
  ];
  ns.App.controller('SigninController', SigninController);
}());
