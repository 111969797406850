var ns;
ns.App.factory('HttpFactory', function($http, BRIEF_PARAM, ModalFactory){
  return {
    createGet: function(endpoint, isBrief){
      //デフォルト引数
      if (typeof isBrief === 'undefined') {
        isBrief = true;
      }
      if(isBrief){
        if (endpoint.indexOf('?') === -1) {
          endpoint += "?" + BRIEF_PARAM;
        }else{
          endpoint += "&" + BRIEF_PARAM;
        }
      }
      return $http.get(endpoint)
      .error(function (data) {
        ModalFactory.createEModalByServerData(data);
      });
    },
    createPost: function(endpoint, params){
      return $http.post(endpoint, params)
      .error(function (data) {
        ModalFactory.createEModalByServerData(data);
      });
    },
    createPut: function(endpoint, params){
      return $http.put(endpoint, params)
      .error(function (data) {
        ModalFactory.createEModalByServerData(data);
      });
    },
    createDelete: function(endpoint){
      return $http.delete(endpoint)
      .error(function (data) {
        ModalFactory.createEModalByServerData(data);
      });
    },
  };
});

