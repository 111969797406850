var ns;
ns.App.factory('FileIOService', function(
    HttpFactory, API_ENDPOINT, DsFactory, Utils
){
  return {
    /**
     * ファイルI/OAPI:PDF帳票の出力指示
     */
    doPrint: function(params){
      var endpoint = API_ENDPOINT + '/sheets/publish';
      //if(!angular.isUndefined(params)){
      //endpoint += Utils.getQueryStr(params);
      //}
      return HttpFactory.createPost(endpoint, params);
    },
    /**
     * ファイルI/OAPI:PDF帳票の出力状況の取得
     */
    getPrintStatus: function(param){
      var endpoint = API_ENDPOINT + '/sheets/' + param + '/status';

      return HttpFactory.createGet(endpoint);
    },
    downloadReport: function(param){
      var endpoint = API_ENDPOINT + '/sheets/' + param + '/download';
      return HttpFactory.createGet(endpoint);
    },

    getDownloadLinkReport: function(param){
      return API_ENDPOINT + '/sheets/' + param + '/download';
    },

    /**
     * ファイルI/OAPI:CSV取込指示
     */
    doImport: function(params){
      var endpoint = API_ENDPOINT + '/import/publish?';
      if(!angular.isUndefined(params)){
        endpoint += Utils.getQueryStr(params);
      }
      return HttpFactory.createPost(endpoint);
    },
    /**
     * ファイルI/OAPI:CSV出力指示
     */
    doExport: function(params){
      var endpoint = API_ENDPOINT + '/export/publish?';
      if(angular.isUndefined(params)){
        return HttpFactory.createPost(endpoint);
      }
      else{
        return HttpFactory.createPost(endpoint, params);
      }
    },
    /**
     * ファイルI/OAPI:CSV出力指示ファイルを置く
     */
    doExportToFTPTrigger: function(params){
      var endpoint = API_ENDPOINT + '/export/ftp/trigger?';
      if(angular.isUndefined(params)){
        return HttpFactory.createPost(endpoint);
      }
      else{
        return HttpFactory.createPost(endpoint, params);
      }
    },
    /**
     * ファイルI/OAPI:取込/出力指示状況の取得
     * @param param
     * @returns {*}
     */
    getIfStatus: function(param){
      var endpoint = API_ENDPOINT + '/if_results/' + param + '/status';
      return HttpFactory.createGet(endpoint);
    },
    getBatchIfStatus: function(params)
    {
      var endpoint = API_ENDPOINT + '/if_results/statuses?';
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getDownloadLinkIfFile: function(id, printName){
      return API_ENDPOINT + '/if_results/' + id + '/file_download/' + printName;
    },
    getDownloadLinkIfLogFile: function(id, printName){
      return API_ENDPOINT + '/if_results/' + id + '/log_download/' + printName;
    }
  };
});

