var ns;
ns.App.factory('IfApisService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    //--------------------------------------------------------------------
    //API連携一括実行 一覧
    getHttpIfApi: function(){
      var endpoint = API_ENDPOINT + '/http_if_apis';
      return HttpFactory.createPost(endpoint);
    },

    //API連携一括実行 一覧
    getUnitIfApis: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/unit_if_apis?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    //API連携一括実行 個別の詳細
    getUnitIfApisData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_if_apis/'+ id +'?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },

    //API連携一括実行 取込 - IfImportControllerのbatchImportを実行
    pullImportData: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_apis/pull_import_data';
      return HttpFactory.createPost(endpoint, params);
    },

    //API連携一括実行 取込
    executeImportAction: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_apis/execute_import_action';
      return HttpFactory.createPost(endpoint, params);
    },


    //API連携一括実行 出力 - IF出力処理の実行
    executeExportAction: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_apis/execute_export_action';
      return HttpFactory.createPost(endpoint, params);
    },

    //API連携一括実行 出力 - IfExportControllerのbatchExportを実行
    pushExecutedExportData: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_apis/push_exported_data';
      return HttpFactory.createPost(endpoint, params);
    },

    //--------------------------------------------------------------------
    //取込出力履歴 一覧
    getUnitIfResults: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/if_results?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },

    //取込出力履歴 個別の詳細
    getUnitIfResultsData: function(sid, id){
      var endpoint = API_ENDPOINT + '/if_results/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },


    //--------------------------------------------------------------------
    //CSV取込ルール設定 一覧
    getUnitIfApiImports: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },

    //CSV取込ルール設定 テンプレートのダウンロード
    getUnitIfApiDownloadImportTemplate: function(sid, id){
      return API_ENDPOINT + '/unit_if_api_imports/' + id + "/download_template?screen_id=" + sid;
    },

    //CSV取込ルール設定 登録画面 IfApi一覧
    getIfApiImports: function(sid){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports/ifapis?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },

    //CSV取込ルール設定 登録画面 IfLayout一覧
    getIfLayoutImports: function(sid, id){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports/' + id + '/if_layouts?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },

    //CSV取込ルール設定 編集画面 UnitIfApi, UnitIfLayout情報
    getUnitIfApiImportData: function(sid, id){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports/' + id + '?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },

    //CSV取込ルール設定 新規登録処理
    storeForUnitIfApiImports: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports';
      return HttpFactory.createPost(endpoint, params);
    },

    //CSV取込ルール設定 更新処理
    updateForUnitIfApiImports: function(id, params){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports/' + id;
      return HttpFactory.createPut(endpoint, params);
    },

    //CSV取込ルール設定 削除処理
    deleteForUnitIfApiImports: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports';
      return HttpFactory.createDelete(endpoint, params);
    },

    //登録処理
    upsertForUnitIfApiImports: function(id, params){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports/' + id + '/upsert';
      return HttpFactory.createPost(endpoint, params);
    },

    //CSV取込ルール設定 複数削除
    multiDeleteForUnitIfApiImports: function(ids){
      var endpoint = API_ENDPOINT + '/unit_if_api_imports/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    },
    //CSV取込ルール設定 利用可否設定・解除
    updateAvailable: function(ids){
      var endpoint = API_ENDPOINT + '/unit_if_apis/available';
      return HttpFactory.createPost(endpoint, {ids:ids});
    },

    //--------------------------------------------------------------------

    //CSV出力ルール設定 一覧
    getUnitIfApiExports: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },

    //CSV出力ルール設定 テンプレートのダウンロード
    getUnitIfApiDownloadExportTemplate: function(sid, id){
      return API_ENDPOINT + '/unit_if_api_exports/' + id + "/download_template?screen_id=" + sid;
    },

    //CSV出力ルール設定 登録画面 IfApi一覧
    getIfApiExports: function(sid){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports/ifapis?screen_id' + sid;
      return HttpFactory.createGet(endpoint);
    },

    //CSV出力ルール設定 登録画面 IfLayout一覧
    getIfLayoutExports: function(sid, id){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports/' + id + '/if_layouts?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },

    //CSV出力ルール設定 編集画面 UnitIfApi, UnitIfLayout情報
    getUnitIfApiExportData: function(sid, id){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports/' + id + '?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },

    //CSV出力ルール設定 新規登録処理
    storeForUnitIfApiExports: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports';
      return HttpFactory.createPost(endpoint, params);
    },

    //CSV出力ルール設定 更新処理
    updateForUnitIfApiExports: function(id, params){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports/' + id;
      return HttpFactory.createPut(endpoint, params);
    },

    //CSV取込ルール設定 削除処理
    deleteForUnitIfApiExports: function(params){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports';
      return HttpFactory.createDelete(endpoint, params);
    },

    //登録処理
    upsertForUnitIfApiExports: function(id, params){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports/' + id + '/upsert';
      return HttpFactory.createPost(endpoint, params);
    },

    //CSV取込ルール設定 複数削除
    multiDeleteForUnitIfApiExports: function(ids){
      var endpoint = API_ENDPOINT + '/unit_if_api_exports/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    }
  };
});

