var ns;
ns.App.factory('UnitGridColumnsService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getUnitGridColumns: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/unit_grid_columns?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitGridColumnsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_grid_columns?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    updateForUnitGridColumn: function(params){
      var endpoint = API_ENDPOINT + '/unit_grid_columns/batch_update';
      return HttpFactory.createPost(endpoint, {"unit_grid_columns": params});
    }
  };
});

