var ns;
ns.App.factory('DeliveriesService', function(API_ENDPOINT, DsFactory, HttpFactory, Utils){
  return {
    getDeliveries: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/deliveries?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getDelivery: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/deliveries/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getDeliveryData: function(sid, id){
      var endpoint = API_ENDPOINT + '/deliveries/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getDeliveriesData: function(sid, params){
      var endpoint = API_ENDPOINT + '/deliveries?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    //FN_50050 届け先追加・編集
    //届け先登録
    storeForDelivery: function(params){
      var endpoint = API_ENDPOINT + '/deliveries';
      return HttpFactory.createPost(endpoint, params);
    },
    //届け先更新
    updateForDelivery: function(params, id){
      var endpoint = API_ENDPOINT + '/deliveries/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //届け先削除
    deleteForDelivery: function(id){
      var endpoint = API_ENDPOINT + '/deliveries/' + id;
      return HttpFactory.createDelete(endpoint);
    },
    //届け先複数削除
    multiDeleteForDelivery: function(ids){
      var endpoint = API_ENDPOINT + '/deliveries/multi_delete';
      return HttpFactory.createPost(endpoint, {ids:ids});
    }
  };
});

