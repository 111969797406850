var ns;
ns.App.factory('StockQualitiesService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils, $http
  ){
  return {
    getStockQualities: function(sid, dsConf){
      var endpoint = API_ENDPOINT + '/stock_qualities?screen_id=' + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getStockQualitiesData: function(sid){
      var endpoint = API_ENDPOINT + '/stock_qualities?screen_id=' + sid;
      //if(!angular.isUndefined(params)){
      //  endpoint += "&" + Utils.getQueryStr(params);
      //}
      return HttpFactory.createGet(endpoint);
    },
    //FN_50010 品質区分登録
    //登録
    storeForStockQuality: function(params){
      var endpoint = API_ENDPOINT + '/stock_qualities';
      return HttpFactory.createPost(endpoint, params);
    },
    //更新
    updateForStockQuality: function(params, id){
      var endpoint = API_ENDPOINT + '/stock_qualities/' + id;
      return HttpFactory.createPut(endpoint, params);
    },
    //削除
    deleteForStockQuality: function(id){
      var endpoint = API_ENDPOINT + '/stock_qualities/' + id;
      return $http.delete(endpoint);
    },
  };
});

