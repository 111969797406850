var ns;
ns.App.factory('UnitCodeExtensionsService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils, $http
  ){
  return {
    getUnitCodeExtensions: function(sid, params, grpcd){
      var endpoint = API_ENDPOINT + '/unit_code_extentions/' + grpcd;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    getUnitCodeExtensionsData: function(sid, params, grpcd){
      var endpoint = API_ENDPOINT + '/unit_code_extentions/' + grpcd;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    //FN_70090 ステータス別設定登録
    //更新
    updateForUnitCodeExtension: function(params){
      var endpoint = API_ENDPOINT + '/unit_code_extentions/batch_update';
      return HttpFactory.createPut(endpoint, {unit_code_extentions: params});
    }
  };
});

