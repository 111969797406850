var ns;
ns.App.factory('UnitRollsService', function(HttpFactory, API_ENDPOINT, DsFactory, Utils){
  return {
    getUnitRolls: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/unit_rolls/editable?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitRollsEditableScreenData: function(sid, params){
      var endpoint = API_ENDPOINT + '/unit_rolls/editable?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitRollsUnitScreenData: function(sid, id, params){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '/unit_screens?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitGridColumnsData: function(sid, id, usid, params){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '/unit_screens/' + usid + '/unit_grid_columns?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitRollsData: function(sid, id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '?screen_id=' + sid;
      return HttpFactory.createGet(endpoint);
    },
    getUnitRoll: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    //FN_70040 コピー作成
    storeForUnitRoll: function(params){
      var endpoint = API_ENDPOINT + '/unit_rolls';
      return HttpFactory.createPost(endpoint, params);
    },
    //FN70070 画面設定_項目表示設定登録
    updateForUnitGridColumn: function(params, id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '/unit_grid_columns/batch_update';
      return HttpFactory.createPut(endpoint, {"unit_grid_columns": params});
    },
    //FN70030 ロール設定_ロール追加・編集登録
    updateForUnitRoll: function(params, id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id;
      return HttpFactory.createPut(endpoint, params);
    },

    updateForUnitReportButtonApi: function(params, id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '/unit_report_button_apis/batch_update';
      return HttpFactory.createPut(endpoint, {"unit_report_button_apis": params});
    },
    updateForUnitReportButtonApiTrue: function(id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '/unit_report_button_apis/batch_enable';
      return HttpFactory.createPut(endpoint);
    },
    updateForUnitReportButtonApiFalse: function(id){
      var endpoint = API_ENDPOINT + '/unit_rolls/' + id + '/unit_report_button_apis/batch_disable';
      return HttpFactory.createPut(endpoint);
    }
  };
});

